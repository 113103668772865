// CSS
import "../css/index.css";

// JS
import "./sign-in";
import "./forgot-password";
import "./resend-confirmation";

// Init Alpine
import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()
