const api = require("./api");

// Redirect if already signed in
export function checkSignedIn() {
  return api
    .checkSignedIn()
    .then((json) => {
      if (json.url) {
        window.location.href = json.url;
      }
    })
    .catch(() => {
      // noop
    });
}
