const api = require("./api");
const { checkSignedIn } = require("./helpers");
const {
  validate,
  validateAll,
  isValid,
  required,
  when,
} = require("./validation");

window.signInForm = function () {
  const urlParams = new URLSearchParams(window.location.search);
  const flash = urlParams.get("flash");

  // Remove flash message from URL
  if (flash) {
    history.replaceState({}, "", window.location.pathname);
  }

  checkSignedIn();

  return {
    login: "",
    password: "",
    remember_me: false,
    otp_code: "",
    dfaMethod: "",
    flash: flash || "",
    flashError: "",
    loading: false,
    validation: {
      login: {
        rules: [required("login is required")],
        error: true,
        message: "",
      },
      password: {
        rules: [required("password is required.")],
        error: true,
        message: "",
      },
      otp_code: {
        rules: [
          when((self) => self.dfaMethod.length, required("2-Step Verification code is required")),
        ],
        error: true,
        message: "",
      },
    },
    validate(field) {
      validate(field, this);
    },
    submit() {
      validateAll(this);
      if (isValid(this)) {
        this.loading = true;
        api
          .signIn(this.login, this.password, this.otp_code, this.remember_me)
          .then((json) => this._onSuccess(json))
          .catch((json) => this._onError(json))
          .finally(() => (this.loading = false));
      }
    },
    resendOtp() {
      this.loading = true;
      api
        .resendOtp(this.login, this.password)
        .then(() => this._showFlash("Code has been sent successfully"))
        .catch(() => this._showFlashError("Unable to resend code"))
        .finally(() => (this.loading = false));
    },
    _onSuccess(json) {
      window.location.href = json.url;
    },
    _onError(json) {
      if (json.error.startsWith("otp_required")) {
        this.dfaMethod = json.error.split(":")[1];
      } else {
        this._showFlashError(json.error);
      }
    },
    _showFlash(msg) {
      this.flash = msg;
      this.flashError = "";
    },
    _showFlashError(msg) {
      this.flash = "";
      this.flashError = msg;
    },
  };
};
