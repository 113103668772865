export function validate(field, obj) {
  const value = obj[field];
  const validation = obj.validation[field];

  for (const rule of validation.rules) {
    const ruleResult = rule.call(obj, value);

    if (ruleResult.error) {
      validation.error = true;
      validation.message = ruleResult.message;
      break;
    }

    validation.error = false;
    validation.message = "";
  }
}

export function isValid(obj) {
  return Object.values(obj.validation).every(function (attr) {
    return attr.error === false;
  });
}

export function validateAll(obj) {
  return Object.keys(obj.validation).forEach(function (field) {
    validate(field, obj);
  });
}

export function required(message) {
  return (value) => {
    if (value) {
      return { error: false, message: "" };
    } else {
      return { error: true, message: message };
    }
  };
}

export function when(condition, fn) {
  return function (value) {
    if (condition.call(this, this)) {
      return fn(value);
    }
    return { error: false, message: "" };
  };
}

export function regex(message, rgx) {
  return (value) => {
    if (rgx.test(value)) {
      return { error: false, message: "" };
    } else {
      return {
        error: true,
        message: message,
      };
    }
  };
}

export function email(message) {
  const rgx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex(message, rgx);
}

// export function subdomain(message) {
//   const rgx = /^[a-z0-9](?:[a-z0-9\-]{0,61}[a-z0-9])?$/;
//   return regex(message, rgx);
// }

export function stripeElementComplete(message) {
  return (value) => {
    if (value.complete) {
      return { error: false, message: "" };
    } else {
      return {
        error: true,
        message: message,
      };
    }
  };
}

export function authCodeRequired(message) {
  return (authCode) => {
    if (authCode.value) {
      return { error: false, message: "" };
    } else {
      return {
        error: true,
        message: message,
      };
    }
  };
}

export function authCodeIsSet(message) {
  return (authCode) => {
    if (authCode.isSet) {
      return { error: false, message: "" };
    } else {
      return {
        error: true,
        message: message,
      };
    }
  };
}
