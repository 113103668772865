const api = require("./api");
const {
  validateAll,
  isValid,
  required,
  email,
  validate,
} = require("./validation");

window.resendConfirmationForm = function () {
  return {
    email: "",
    flash: "",
    flashError: "",
    loading: "",
    validation: {
      email: {
        rules: [required("email is required"), email("email is invalid")],
        error: true,
        message: "",
      },
    },
    submit() {
      validateAll(this);
      if (isValid(this)) {
        this.loading = true;
        api
          .resendConfirmation(this.email)
          .then((json) => this._onSuccess(json))
          .catch((json) => this._onError(json))
          .finally(() => (this.loading = false));
      }
    },
    validate(field) {
      validate(field, this);
    },
    _onSuccess(json) {
      window.location.href = `/users/sign_in?flash=${json.flash}`;
    },
    _onError(json) {
      this.flashError = json.error;
    },
  };
};
