export function apiCall({path, method, data}) {
  return new Promise((resolve, reject) => {
    path = path.startsWith('/') ? path.substr(1) : path

    return fetch(`/api/${path}`, {
      method: method,
      headers: {
        Accept: "application/json, text/javascript, */*",
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
      body: JSON.stringify(data),
      credentials: "include",
    })
      .then((res) => {
        if (res.ok) {
          res.json().then(resolve);
        } else if ([401, 422].includes(res.status)) {
          res.json().then(reject);
        } else {
          reject({error: res.statusText, status: res.status});
        }
      })
      .catch((err) => {
        reject({error: err.message, status: err.status});
      });
  });
}

export function signIn(login, password, otp_code, remember_me) {
  let user = {login, password};

  if (otp_code.length) {
    user = {...user, otp_code, remember_me};
  }

  return apiCall({
    path: "/users/login",
    method: "post",
    data: {
      user: user,
    },
  });
}

export function validateAuthCode(authCode) {
  return apiCall({
    path: "/users/register/validate_auth_code",
    method: "post",
    data: { auth_code: authCode },
  });
}

export function signUpNew(user, recaptchaToken) {
  return apiCall({
    path: "/users/register/new",
    method: "post",
    data: {
      user: user,
      "g-recaptcha-response": recaptchaToken,
    },
  });
}

export function signUpExisting(user, recaptchaToken) {
  return apiCall({
    path: "/users/register/existing",
    method: "post",
    data: {
      user: user,
      "g-recaptcha-response": recaptchaToken,
    },
  });
}

export function resendOtp(login, password) {
  return apiCall({
    path: "/users/resend_code",
    method: "post",
    data: {
      user: {login, password},
    },
  });
}

export function checkSignedIn() {
  return apiCall({
    path: "/users/check_signed_in",
    method: "get",
  });
}

export function forgotPassword(email) {
  return apiCall({
    path: "/users/password",
    method: "post",
    data: {
      user: {email},
    },
  });
}

export function resendConfirmation(email) {
  return apiCall({
    path: "/users/confirmation",
    method: "post",
    data: {
      user: {email},
    },
  });
}
